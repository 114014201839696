import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/buildhome/repo/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Welcome to the Analytics API. This API can be used to retrieve data collected by Nucleus.`}</p>
    <blockquote>
      <p parentName="blockquote">{`This API is deprecated, please use the new API V1 for new integrations.`}</p>
    </blockquote>
    <h2 {...{
      "id": "authentication"
    }}>{`Authentication`}</h2>
    <p>{`Nucleus expects for the API key to be included in all requests to the server in a header that looks like the following:`}</p>
    <p><inlineCode parentName="p">{`Authorization: your_access_token`}</inlineCode></p>
    <p>{`Example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-shell"
      }}>{`curl "api_endpoint_here"
    -H "Authorization: your_access_token"
`}</code></pre>
    <p>{`You may also send it in the body of a POST request as the parameter `}<inlineCode parentName="p">{`token`}</inlineCode>{`.`}</p>
    <h2 {...{
      "id": "quick-glance"
    }}>{`Quick Glance`}</h2>
    <p>{`Fetch the number of users, installs and more during the last 24 hours.`}</p>
    <h4 {...{
      "id": "http-request"
    }}>{`HTTP Request`}</h4>
    <p><inlineCode parentName="p">{`GET https://nucleus.sh/app/:appId/analytics/quickglance`}</inlineCode></p>
    <p>{`It will return the numbers like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "realTime": 5,
    "installs": 9,
    "users": 29,
    "appStarts": 75
}
`}</code></pre>
    <h2 {...{
      "id": "daily-analytics"
    }}>{`Daily analytics`}</h2>
    <p>{`The daily analytics data for your application. `}</p>
    <p>{`You need to supply a date interval as timestamps.`}</p>
    <h4 {...{
      "id": "http-request-1"
    }}>{`HTTP Request`}</h4>
    <p><inlineCode parentName="p">{`GET https://nucleus.sh/app/:appId/analytics`}</inlineCode></p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Parameter`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Optional`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`start`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Timestamp for beginning of interval`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`end`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`required`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Timestamp for end of interval`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`utcOffset`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`optional`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Your timezone UTC offset (`}<strong parentName="td">{`in minutes`}</strong>{`) so we can return the appropriate dates`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`It will return various data on your app like the following.
Where each object contains a key/value pair for every day in the period you supplied.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-json"
      }}>{`{
    "totalEvents": {},
    "usage": {},
    "newUsers": {},
    "activeUsers": {},
    "nonNewUsers": {},
    "hours": {},
    "platforms": {},
    "errors": {},
    "languages": {},
    "ram": {},
    "versions": {},
    "countries": {}
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      